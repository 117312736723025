import {useEffect, useRef, useState} from "react";
import {ReactComponent as ArrowDropUpIcon} from './arrow_drop_up_icon.svg';
import {ReactComponent as ArrowDropDownIcon} from './arrow_drop_down.svg';
import {ReactComponent as CancelIcon} from './cancel_icon.svg';
import BrowserView from '../../../../views/BrowserView';
import TabletView from '../../../../views/TabletView';
import MobileView from '../../../../views/MobileOnlyView';
import styles from './CustomSelectFilter.module.css';
import {useOutsideClickDetector} from "../../../../hooks/useOutsideClickDetector";
import ReactDOM from "react-dom";

export default function CustomSelectFilter({
                                               options,
                                               defaultOption,
                                               onChangeFilter
                                           }) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [isOptionSelected, setIsOptionSelected] = useState(false);
    const frameRef = useRef(null);
    const dropdownRef = useRef(null);
    useOutsideClickDetector(frameRef, () => closeOptions(), [dropdownRef]);

    useEffect(() => {
        const isOptionCompleted = options.some(option => option.label === selectedOption?.label);
        if (defaultOption && selectedOption?.label !== defaultOption.label && !isOptionCompleted) {
            setSelectedOption(defaultOption);
        }
    }, [defaultOption, options, selectedOption?.label]);

    useEffect(() => {
        const isSelected = options?.some(option => option.label === selectedOption?.label);
        setIsOptionSelected(isSelected);
    }, [options, selectedOption])

    useEffect(() => {
        const handleCloseDropdowns = () => {
            closeOptions();
        };

        window.addEventListener("closeDropdowns", handleCloseDropdowns);

        return () => {
            window.removeEventListener("closeDropdowns", handleCloseDropdowns);
        };
    }, []);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    }

    const calculateDropdownPosition = () => {
        const rect = frameRef.current.getBoundingClientRect();
        return {
            top: rect.bottom + window.scrollY,
            left: rect.left + window.scrollX,
            width: rect.width,
        };
    };

    const dropdownStyle = isOpen && calculateDropdownPosition();

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
        onChangeFilter(option);
    };

    const closeOptions = () => {
        setIsOpen(false);
    }

    const resetOptions = () => {
        closeOptions();
        setSelectedOption(defaultOption);
        onChangeFilter(defaultOption);
    }

    const renderDropdown = () => (
        <div className={styles.DropdownRelative}
             ref={dropdownRef}
             style={{
                 position: 'absolute',
                 top: `${dropdownStyle.top + 2}px`,
                 left: `${dropdownStyle.left}px`,
                 width: `${dropdownStyle.width}px`,
                 zIndex: 9999,
             }}>
            {
                options.map((option) => (
                    <div
                        key={option.value}
                        className={styles.Option}
                        onClick={() => handleOptionClick(option)}>
                        {option.label}
                    </div>
                ))
            }
        </div>
    );


    return (
        <>
            <BrowserView className={styles.Browser}>
                <div ref={frameRef} className={styles.CustomSelect}>
                    <button onClick={isOptionSelected ? resetOptions : toggleDropdown} className={styles.SelectButton}>
                    <span className={isOptionSelected ? styles.OptionSelected : styles.DefaultSelected}>
                        {selectedOption?.label}
                    </span>
                        {
                            isOptionSelected ? <CancelIcon/> : (isOpen ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>)
                        }
                    </button>
                    {
                        isOpen && (
                            <div className={styles.Dropdown}>
                                {
                                    options.map((option) => (
                                        <div
                                            key={option.value}
                                            className={styles.Option}
                                            onClick={() => handleOptionClick(option)}>
                                            {option.label}
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    }
                </div>
            </BrowserView>
            <TabletView className={styles.Tablet}>
                <div ref={frameRef} className={styles.CustomSelect}>
                    <button onClick={isOptionSelected ? resetOptions : toggleDropdown} className={styles.SelectButton}>
                    <span className={isOptionSelected ? styles.OptionSelected : styles.DefaultSelected}>
                        {selectedOption?.label}
                    </span>
                        {
                            isOptionSelected ? <CancelIcon/> : (isOpen ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>)
                        }
                    </button>
                    {
                        isOpen && ReactDOM.createPortal(renderDropdown(), document.body)
                    }
                </div>
            </TabletView>
            <MobileView className={styles.Mobile}>
                <div ref={frameRef} className={styles.CustomSelect}>
                    <button onClick={isOptionSelected ? resetOptions : toggleDropdown} className={styles.SelectButton}>
                    <span className={isOptionSelected ? styles.OptionSelected : styles.DefaultSelected}>
                        {selectedOption?.label}
                    </span>
                        {
                            isOptionSelected ? <CancelIcon/> : (isOpen ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>)
                        }
                    </button>
                    {
                        isOpen && ReactDOM.createPortal(renderDropdown(), document.body)
                    }
                </div>
            </MobileView>
        </>

    );
}
