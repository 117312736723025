import Headline from "../../../Headline/Headline";
import React from "react";
import localizations from "../../../../localizations/localizations";
import styles from "./LogTableHead.module.css";

export default function LogTableHead() {
    return (
        <thead className={styles.LogTableHead}>
        <tr>
            <th>
                <Headline contentMargin={'0'}
                          contentFontFamily={'Inter, sans-serif'}
                          contentFontWeight={'var(--body2-bold-font-weight)'}
                          contentFontSize={'var(--body2-bold-font-size)'}
                          contentLineHeight={'var(--body2-bold-line-height)'}
                          contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                    {localizations.smartIdHistoryDate}
                </Headline>
            </th>
            <th>
                <Headline contentMargin={'0'}
                          contentFontFamily={'Inter, sans-serif'}
                          contentFontWeight={'var(--body2-bold-font-weight)'}
                          contentFontSize={'var(--body2-bold-font-size)'}
                          contentLineHeight={'var(--body2-bold-line-height)'}
                          contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                    {localizations.smartIdHistoryDocumentName}
                </Headline>
            </th>
            <th>
                <Headline contentMargin={'0'}
                          contentFontFamily={'Inter, sans-serif'}
                          contentFontWeight={'var(--body2-bold-font-weight)'}
                          contentFontSize={'var(--body2-bold-font-size)'}
                          contentLineHeight={'var(--body2-bold-line-height)'}
                          contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                    {localizations.smartIdHistoryCompany}
                </Headline>
            </th>
            <th>
                <Headline contentMargin={'0'}
                          contentFontFamily={'Inter, sans-serif'}
                          contentFontWeight={'var(--body2-bold-font-weight)'}
                          contentFontSize={'var(--body2-bold-font-size)'}
                          contentLineHeight={'var(--body2-bold-line-height)'}
                          contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                    {localizations.smartIdHistoryService}
                </Headline>
            </th>
            <th>
                <Headline contentMargin={'0'}
                          contentFontFamily={'Inter, sans-serif'}
                          contentFontWeight={'var(--body2-bold-font-weight)'}
                          contentFontSize={'var(--body2-bold-font-size)'}
                          contentLineHeight={'var(--body2-bold-line-height)'}
                          contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                    {localizations.smartIdHistoryTypeOperation}
                </Headline>
            </th>
            <th>
                <Headline contentMargin={'0'}
                          contentFontFamily={'Inter, sans-serif'}
                          contentFontWeight={'var(--body2-bold-font-weight)'}
                          contentFontSize={'var(--body2-bold-font-size)'}
                          contentLineHeight={'var(--body2-bold-line-height)'}
                          contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                    {localizations.smartIdHistoryKeyType}
                </Headline>
            </th>
            <th>
                <Headline contentMargin={'0'}
                          contentFontFamily={'Inter, sans-serif'}
                          contentFontWeight={'var(--body2-bold-font-weight)'}
                          contentFontSize={'var(--body2-bold-font-size)'}
                          contentLineHeight={'var(--body2-bold-line-height)'}
                          contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                </Headline>
            </th>
        </tr>
        </thead>
    )
}