import React, {useEffect, useRef, useState} from 'react';
import Row from "../../../Row/Row";
import styles from './LogRow.module.css'
import {ReactComponent as OpenTabIcon} from './openTabIcon.svg';
import {ReactComponent as TabIsOpened} from './tabOpenedIcon.svg';
import {ReactComponent as ExpandLessIcon} from './expand_less.svg';
import {ReactComponent as ExpandMoreIcon} from './expand_more.svg';
import localizations from "../../../../localizations/localizations";

function LogRow({smartIdLog, organizations}) {
    const operationTypes = {
        1: localizations.smartIdHistoryTypeOperationFilterGeneration,
        2: localizations.smartIdHistoryTypeOperationFilterSign,
        3: localizations.smartIdHistoryTypeOperationFilterEncrypt,
        4: localizations.smartIdHistoryTypeOperationFilterDecrypt
    };
    const certificateTypes = {
        0: localizations.clientsCertificatesTypeSign,
        1: localizations.clientsCertificatesTypeStamp
    };
    const contentRef = useRef(null);
    const [contentHeight, setContentHeight] = useState(0);
    const [isExpanded, setIsExpanded] = useState(false);
    const [showAllDocs, setShowAllDocs] = useState(false);
    const isDocumentListExist = smartIdLog?.documentNames && smartIdLog.documentNames.length > 0;

    useEffect(() => {
        if ((isExpanded && contentRef.current) || showAllDocs) {
            setContentHeight(contentRef.current.scrollHeight);
        } else {
            setContentHeight(0);
        }
    }, [isExpanded, showAllDocs]);


    const handleRowClick = () => {
        if (isDocumentListExist) {
            if (isExpanded && showAllDocs) {
                setIsExpanded(false);
                setShowAllDocs(false);
            } else {
                setIsExpanded(!isExpanded)
            }
        }
    };

    const handleShowAllDocs = () => {
        setShowAllDocs(!showAllDocs);
    }

    function getDocName(documentName) {
        return (documentName && documentName.trim()) ? documentName : "-";
    }

    function convertTimestampToTime(timestamp) {
        const date = new Date(timestamp);
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    }

    function getLocalizedTypeOperation(typeOperation) {
        return operationTypes[typeOperation] || "-";
    }

    function getLocalizedTypeKey(typeKey) {
        return certificateTypes[typeKey] || "-";
    }

    function getCompanyName(edrpou) {
        if (smartIdLog.userType === 0) {
            return localizations.smartIdHistoryBankName;
        }
        if (smartIdLog.userType === 2) {
            return localizations.smartIdHistoryIndividualName;
        }
        const org = organizations.find((object) => object.value === edrpou);
        return org ? org.label : "-";
    }

    return (
        <>
            <tr key={smartIdLog.index}
                onClick={handleRowClick}
                style={{cursor: isDocumentListExist ? 'pointer' : 'default'}}>
                <td>
                    <Row horizontal={true} contentFontSize={'var(--body2-font-size)'}
                         contentLineHeight={'var(--body2-line-height)'}>
                        {convertTimestampToTime(smartIdLog?.date)}
                    </Row>
                </td>
                <td>
                    <Row horizontal={true} contentFontSize={'var(--body2-font-size)'}
                         contentLineHeight={'var(--body2-line-height)'}>
                        {getDocName(smartIdLog?.documentName)}
                    </Row>
                </td>
                <td>
                    <Row horizontal={true} contentFontSize={'var(--body2-font-size)'}
                         contentLineHeight={'var(--body2-line-height)'}>
                        {getCompanyName(smartIdLog?.edrpou)}
                    </Row>
                </td>
                <td>
                    <Row horizontal={true} contentFontSize={'var(--body2-font-size)'}
                         contentLineHeight={'var(--body2-line-height)'}>
                        {smartIdLog?.serviceId}
                    </Row>
                </td>
                <td>
                    <Row horizontal={true} contentFontSize={'var(--body2-font-size)'}
                         contentLineHeight={'var(--body2-line-height)'}>
                        {getLocalizedTypeOperation(smartIdLog?.type)}
                    </Row>
                </td>
                <td>
                    <Row horizontal={true} contentFontSize={'var(--body2-font-size)'}
                         contentLineHeight={'var(--body2-line-height)'}>
                        {getLocalizedTypeKey(smartIdLog?.certificateType)}
                    </Row>
                </td>
                <td>
                    {
                        isDocumentListExist && (
                            isExpanded ?
                                <TabIsOpened className={styles.Icon}/> :
                                <OpenTabIcon className={styles.Icon}/>
                        )
                    }
                </td>
            </tr>
            {
                <tr className={styles.DocList}>
                    <td colSpan="7" className={styles.Td}>
                        <div ref={contentRef}
                             style={{
                                 maxHeight: `${contentHeight}px`,
                                 overflow: 'hidden',
                                 transition: 'max-height 0.3s ease' // Плавная анимация изменения высоты
                             }}>
                            <ul>
                                {
                                    smartIdLog.documentNames
                                        .slice(0, showAllDocs ? smartIdLog.documentNames.length : 3)
                                        .map((name, index) => (
                                            <li key={index}>{name}</li>
                                        ))
                                }
                            </ul>
                            {
                                smartIdLog.documentNames.length > 3 &&
                                <div className={styles.ExpandText} onClick={handleShowAllDocs}>
                                    <span>{showAllDocs ? localizations.smartIdHistoryHydeDocuments : localizations.smartIdHistoryShowDocuments}</span>
                                    {showAllDocs ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                </div>
                            }
                        </div>
                    </td>
                </tr>
            }
        </>
    );
}

export default LogRow