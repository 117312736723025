import {useEffect} from 'react';

function useOutsideClickDetector(ref, outsideClickCallback, ignoreElements = []) {
    useEffect(() => {
        function handleOutsideClick(event) {
            // Проверяем, был ли клик по элементу, который нужно игнорировать
            const shouldIgnore = ignoreElements.some((ignore) => {
                if (typeof ignore === 'string') {
                    // Если передан CSS-класс
                    return event.target.closest(ignore);
                }
                if (ignore instanceof HTMLElement) {
                    // Если передан DOM-элемент
                    return ignore.contains(event.target);
                }
                if (ignore?.current) {
                    // Если передан реф
                    return ignore.current.contains(event.target);
                }
                return false;
            });

            if (!ref.current || ref.current.contains(event.target) || shouldIgnore) {
                return; // Не вызываем callback, если клик внутри рефа или игнорируемых элементов
            }

            outsideClickCallback && outsideClickCallback();
        }

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [ref, outsideClickCallback, ignoreElements]);
}

export {useOutsideClickDetector};
