export const GET_AUTH_TOKEN_BACK_PATH = '/back/get-auth-token';
export const GET_AUTH_USER_BACK_PATH = '/back/get-auth-user';
export const GET_AUTH_STATUS_BACK_PATH = '/back/get-auth-status';
export const GET_INTERNAL_TOKEN_PATH = '/back/get-internal-token';
export const CLOSE_TOKEN_PATH = '/back/close-token';
export const GET_REVOKED_CERTIFICATES_BACK_PATH = '/back/get-revoked-certificates';
export const GET_ACSK_CERTIFICATES_BACK_PATH = '/back/get-acsk-certificates';
export const GET_PERSONAL_CERTIFICATES_BACK_PATH = '/back/get-personal-certificates';
export const GET_CERTIFICATES_BACK_PATH = '/back/get-certificates';
export const GET_PDF_BACK_PATH = '/back/get-pdf';
export const REVOCATION_REQUEST_BACK_PATH = '/back/revocation-request';
export const UNPUBLICATION_REQUEST_BACK_PATH = '/back/unpublication-request';
export const GET_CLIENT_CERTIFICATES_BACK_PATH = '/back/get-client-certificates';
export const GET_SMART_ID_HISTORY_BACK_PATH = '/back/get-smart-id-history';
