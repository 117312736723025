import React, {Fragment, useEffect, useRef, useState} from "react";
import {useOutsideClickDetector} from "../../hooks/useOutsideClickDetector";
import localizations from "../../localizations/localizations";
import Row from "../Row/Row";
import BrowserView from '../../views/BrowserView';
import TabletView from '../../views/TabletView';
import MobileOnlyView from '../../views/MobileOnlyView';
import styles from './UserPersonalSmartIdArchive.module.css';
import LogTableBody from "./LogTable/LogTableBody/LogTableBody";
import axios from "axios";
import * as backPaths from '../../paths/backPaths';
import {ReactComponent as UnauthorizedUserIcon} from './unauthorizedUserIcon.svg';
import LogTableHead from "./LogTable/LogTableHead/LogTableHead";
import LogTableFilters from "./LogTableFilters/LogTableFilters";
import MobileUserLog from "./MobileUserLog/MobileUserLog";

const UserPersonalSmartIdArchive = (props) => {
    const {
        isAuthorized,
        authorizedToken,
        login,
        checkAuthorizedToken,
        errorGetSmartIdHistory
    } = props;
    const frameRef = useRef();
    const blurRef = useRef();
    const [loading, setLoading] = useState(false);
    const [smartIdData, setSmartIdData] = useState({
        countOfLogs: undefined,
        organizations: [],
        lastId: undefined,
        smartIdLogs: [],
        bankEmployee: false
    });
    const [smartIdLogsList, setSmartIdLogsList] = useState([]);
    useOutsideClickDetector(frameRef, () => closeModals());
    const [organizations, setOrganizations] = useState([{
        value: "", //EDRPOU
        label: "",
        orgId: "",
    }]);
    const [dateRangeFilter, setDateRangeFilter] = useState({
        startDate: dateMonthAgo(),
        endDate: new Date(),
    });
    const [nextPackDateRange, setNextPackDateRange] = useState({
        startDate: undefined,
        endDate: undefined,
    })
    const [typeSignFilter, setTypeSignFilter] = useState(null);
    const [typeOperationFilter, setTypeOperationFilter] = useState(null);
    const [organizationFilter, setOrganizationFilter] = useState(null);
    const [shouldShowNextLogsButton, setShouldShowNextLogsButton] = useState(true);
    const [loadingNextLogsButton, setLoadingNextLogsButton] = useState(false);

    let shouldShowLoading = isAuthorized && loading;
    let shouldShowEmptyMessage = !loading && isAuthorized && smartIdLogsList?.length === 0;
    let shouldShowSmartIdLogsList = !loading && isAuthorized && smartIdLogsList?.length > 0;
    let showNextLogsButton = !loading && isAuthorized && shouldShowNextLogsButton;

    useEffect(() => {
        if (dateRangeFilter.endDate && isAuthorized) {
            setLoading(true);
            let edrpou;
            let orgId;
            if (organizationFilter && organizationFilter?.value !== "0") {
                edrpou = organizationFilter.value;
                orgId = organizationFilter.orgId;
            } else {
                edrpou = "";
                orgId = "";
            }
            const startDate = formatDate(dateRangeFilter.startDate)
            const endDate = getEndDateForRequest(dateRangeFilter.endDate)
            const request = {
                token: authorizedToken,
                beginDate: startDate,
                endDate,
                edrpou,
                orgId
            };
            checkAuthorizedToken(() => getSmartIDHistory(request, dateRangeFilter.endDate));
        }
        // eslint-disable-next-line
    }, [organizationFilter, dateRangeFilter.endDate, isAuthorized])

    useEffect(() => {
        if (smartIdData) {
            if (smartIdData?.smartIdLogs.length > 0) {
                let sortedSmartIdData = smartIdData.smartIdLogs;
                if (typeSignFilter) {
                    const {userType, certificateType} = gatUserTypeFromFilter();
                    if (userType !== undefined && certificateType !== undefined) {
                        sortedSmartIdData = sortedSmartIdData.filter(log => log.certificateType === certificateType && log.userType === userType);
                    }
                }
                if (typeOperationFilter) {
                    const type = getOperationTypeFromFilter();
                    if (type !== undefined) {
                        sortedSmartIdData = sortedSmartIdData.filter(log => log.type === type);
                    }
                }
                setSmartIdLogsList(sortedSmartIdData)
            } else {
                setSmartIdLogsList([]);
            }
            const organizationsResponse = smartIdData?.organizations;
            if (organizationsResponse && organizationsResponse.length > 0) {
                const userOrganizations = organizationsResponse.map((org) => {
                    return {
                        value: org.edrpou,
                        label: org.orgName,
                        orgId: org.orgId,
                    }
                });
                setOrganizations(userOrganizations)
            }
        }
        // eslint-disable-next-line
    }, [smartIdData, typeSignFilter, typeOperationFilter]);


    useEffect(() => {
        if (smartIdData && smartIdLogsList.length > 0) {
            let startDate;
            let endDate;
            const now = new Date();
            if (smartIdData?.countOfLogs !== 0) {
                startDate = new Date(smartIdLogsList[smartIdLogsList?.length - 1].date);
                const afterMonth = new Date(startDate);
                afterMonth.setMonth(afterMonth.getMonth() + 1);
                endDate = afterMonth > now ? now : afterMonth;
                setNextPackDateRange({startDate, endDate});
            }
        }
        // eslint-disable-next-line
    }, [dateRangeFilter.endDate, smartIdData, smartIdLogsList]);


    function getOperationTypeFromFilter() {
        const value = Number(typeOperationFilter.value);
        return value === 0 ? undefined : value;
    }

    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    function dateMonthAgo() {
        const date = new Date();
        date.setMonth(date.getMonth() - 1);
        return date
    }

    function gatUserTypeFromFilter() {
        const filteredObject = {
            userType: undefined,
            certificateType: undefined
        }
        switch (typeSignFilter.value) {
            case "0":
                break;
            case "1": {
                filteredObject.userType = 2;
                filteredObject.certificateType = 0;
                break;
            }
            case "2": {
                filteredObject.userType = 1;
                filteredObject.certificateType = 0;
                break;
            }
            case "3": {
                filteredObject.userType = 1;
                filteredObject.certificateType = 1;
                break;
            }
            case "4": {
                filteredObject.userType = 0;
                filteredObject.certificateType = 0;
                break;
            }
            case "5": {
                filteredObject.userType = 0;
                filteredObject.certificateType = 1
                break;
            }
            default:
                break;
        }
        return filteredObject;
    }

    function getEndDateForRequest(endDate) {
        const date = new Date();
        const endDateLocalString = endDate.toLocaleDateString();
        const currentDateLocalString = date.toLocaleDateString();
        if (endDateLocalString === currentDateLocalString) {
            const endDateNextDay = new Date(date);
            endDateNextDay.setDate(date.getDate() + 1);
            return formatDate(endDateNextDay);
        } else {
            return formatDate(endDate);
        }
    }

    async function getSmartIDHistory(data, endDate) {
        try {
            const response = await axios.post(backPaths.GET_SMART_ID_HISTORY_BACK_PATH, data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept-Language': localizations.smartIdHistoryLocaleHeader
                    }
                });
            const result = response?.data;
            setSmartIdData(result);
            onChangeNextLogsButtonState(result, endDate)
        } catch (error) {
            console.error('Error fetching SmartID history:', error);
            errorGetSmartIdHistory(920, error.response.headers.requestid)
        } finally {
            setLoading(false);
        }
    }

    async function getNextSmartIdHistory(data) {
        try {
            return await axios.post(backPaths.GET_SMART_ID_HISTORY_BACK_PATH, data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept-Language': localizations.smartIdHistoryLocaleHeader
                    }
                });
        } catch (error) {
            const requestId = error.response?.headers?.requestid || 'Unknown';
            console.error('Error fetching SmartID history:', error);
            errorGetSmartIdHistory(920, requestId);
            return null;
        } finally {
            setLoading(false);
        }
    }

    async function getNextDateRange() {
        setLoadingNextLogsButton(true);  // Установить состояние загрузки
        const {startDate, endDate} = nextPackDateRange;
        const {value: edrpou = "", orgId = ""} = organizationFilter || {};
        const isFullLogMatch = smartIdData?.countOfLogs === smartIdLogsList?.length;
        const hasLastId = smartIdData?.lastId;

        const request = {
            token: authorizedToken,
            endDate: getEndDateForRequest(endDate),
            edrpou: edrpou !== "0" ? edrpou : "",
            orgId: edrpou !== "0" ? orgId : "",
            ...(isFullLogMatch || !hasLastId
                ? {beginDate: formatDate(startDate)}
                : {lastId: smartIdData.lastId})
        };

        checkAuthorizedToken(() => sendGetNextLogsRequest(request, endDate));
    }

    async function sendGetNextLogsRequest(request, endDate) {
        try {
            const result = await getNextSmartIdHistory(request);
            const nextSmartIdLogs = result?.data || {};
            onChangeNextLogsButtonState(nextSmartIdLogs, endDate)
            setSmartIdData(prev => ({
                lastId: nextSmartIdLogs.lastId,
                smartIdLogs: [...prev.smartIdLogs, ...(nextSmartIdLogs?.smartIdLogs || [])],
                countOfLogs: nextSmartIdLogs.countOfLogs,
                organizations: nextSmartIdLogs.organizations,
                bankEmployee: nextSmartIdLogs.bankEmployee
            }));
        } catch (error) {
            console.error("Error fetching next logs:", error);
        } finally {
            setLoadingNextLogsButton(false);
        }
    }

    function onChangeNextLogsButtonState(nextSmartIdLogs, endDate) {
        const {smartIdLogs = [], countOfLogs} = nextSmartIdLogs;
        const endDateLocalString = endDate.toLocaleDateString();
        const currentDateLocalString = new Date().toLocaleDateString();
        const now = new Date();

        if (smartIdLogs.length === 0) {
            const newStartDate = new Date(endDate);
            const newEndDate = new Date(newStartDate);
            newEndDate.setMonth(newEndDate.getMonth() + 1);

            setNextPackDateRange({
                startDate: newStartDate,
                endDate: newEndDate > now ? now : newEndDate,
            });
        } else {
            // Если данные есть, определяем новый диапазон
            let startDate = new Date(smartIdLogs[smartIdLogs?.length - 1]?.date || endDate);
            let endDateNext = new Date(startDate);
            endDateNext.setMonth(endDateNext.getMonth() + 1);
            setNextPackDateRange({
                startDate,
                endDate: endDateNext > now ? now : endDateNext,
            });
        }
        if (smartIdLogs.length === countOfLogs && endDateLocalString === currentDateLocalString) {
            setShouldShowNextLogsButton(false);
        } else {
            setShouldShowNextLogsButton(true);
        }
    }

    function onChangeDateRange(dateRange) {
        setDateRangeFilter(dateRange);
    }

    function onChangeTypeOperation(option) {
        setTypeOperationFilter(option)
    }

    function onChangeOrganizationFilter(organization) {
        setOrganizationFilter(organization)
    }

    function onChangeTypeSignFilter(option) {
        setTypeSignFilter(option)
    }

    function closeModals() {
        blurRef.current.style.display = 'none';
    }

    function groupLogsByDate(logs) {
        return logs.reduce((acc, log) => {
            const date = new Date(log.date);
            const formattedDate = date.toLocaleDateString(localizations.calendarLocaleMonth, {
                day: '2-digit',
                month: 'long',
            });
            if (!acc[formattedDate]) {
                acc[formattedDate] = [];
            }
            acc[formattedDate].push(log);
            return acc;
        }, {});
    }

    const groupedLogs = groupLogsByDate(smartIdLogsList);

    const Loading = () => (
        <div className={styles.Loading}>
            <Row horizontal={true}>
                {localizations.personalCertificatesLoading}
            </Row>
        </div>
    );

    const Empty = () => (
        <div className={styles.Empty}>
            <Row horizontal={true}>
                {localizations.smartIdHistoryEmpty}
            </Row>
        </div>
    );

    return (
        <Fragment>
            <BrowserView viewClassName={styles.Browser}>
                {
                    !isAuthorized && <div className={styles.Authorization}>
                        <UnauthorizedUserIcon className={styles.Icon}/>
                        <div className={styles.Text}>
                            {localizations.userSmartIdArchiveAuthorization}
                        </div>
                        <button className={styles.Button} onClick={login}>
                            {localizations.userSmartIdArchiveAuthorizationButton}
                        </button>
                    </div>
                }
                {
                    isAuthorized && (
                        <div className={styles.Content}>
                            <LogTableFilters
                                isEmployee={smartIdData?.bankEmployee}
                                organizationsOptions={organizations}
                                onDateRangeSelect={onChangeDateRange}
                                onChangeTypeOperation={onChangeTypeOperation}
                                onChangeTypeSignFilter={onChangeTypeSignFilter}
                                onChangeOrganizationFilter={onChangeOrganizationFilter}
                                selectedDateRange={dateRangeFilter}
                            />
                        </div>
                    )
                }
                {
                    shouldShowLoading && <Loading/>
                }
                {
                    shouldShowEmptyMessage && <Empty/>
                }
                {
                    shouldShowSmartIdLogsList && (
                        <div className={styles.Content}>
                            <table className={styles.SmartIDLogs}>
                                <LogTableHead/>
                                <LogTableBody groupedLogs={groupedLogs}
                                              organizations={organizations}/>
                            </table>
                        </div>
                    )
                }
                {
                    showNextLogsButton && <div className={styles.NextLogs}>
                        <button className={`${styles.NextLogsButton} ${loadingNextLogsButton ? styles.Disabled : ''}`}
                                disabled={loadingNextLogsButton}
                                onClick={getNextDateRange}>
                            {localizations.smartIdHistoryNextPeriodButtonText}
                            {nextPackDateRange.startDate?.toLocaleDateString()} - {nextPackDateRange.endDate?.toLocaleDateString()}
                            {
                                loadingNextLogsButton && <span className={styles.Spinner}></span>
                            }
                        </button>
                    </div>
                }
            </BrowserView>
            <TabletView viewClassName={styles.Tablet}>
                {
                    !isAuthorized && <div className={styles.Authorization}>
                        <UnauthorizedUserIcon className={styles.Icon}/>
                        <div className={styles.Text}>
                            {localizations.userSmartIdArchiveAuthorization}
                        </div>
                        <button className={styles.Button} onClick={login}>
                            {localizations.userSmartIdArchiveAuthorizationButton}
                        </button>
                    </div>
                }
                {
                    isAuthorized && (
                        <div className={styles.Content}>
                            <LogTableFilters
                                isEmployee={smartIdData?.bankEmployee}
                                organizationsOptions={organizations}
                                onDateRangeSelect={onChangeDateRange}
                                onChangeTypeOperation={onChangeTypeOperation}
                                onChangeTypeSignFilter={onChangeTypeSignFilter}
                                onChangeOrganizationFilter={onChangeOrganizationFilter}
                                selectedDateRange={dateRangeFilter}
                            />
                        </div>
                    )
                }
                {
                    shouldShowLoading && <Loading/>
                }
                {
                    shouldShowEmptyMessage && <Empty/>
                }
                {
                    shouldShowSmartIdLogsList && (
                        <div className={styles.Content}>
                            <table className={styles.SmartIDLogs}>
                                <LogTableHead/>
                                <LogTableBody groupedLogs={groupedLogs}
                                              organizations={organizations}/>
                            </table>
                        </div>
                    )
                }
                {
                    showNextLogsButton && <div className={styles.NextLogs}>
                        <button className={`${styles.NextLogsButton} ${loadingNextLogsButton ? styles.Disabled : ''}`}
                                disabled={loadingNextLogsButton}
                                onClick={getNextDateRange}>
                            {localizations.smartIdHistoryNextPeriodButtonText} {nextPackDateRange.startDate?.toLocaleDateString()} - {nextPackDateRange.endDate?.toLocaleDateString()}
                            {loadingNextLogsButton && <span className={styles.Spinner}></span>}
                        </button>
                    </div>
                }
            </TabletView>
            <MobileOnlyView viewClassName={styles.Mobile}>
                {
                    !isAuthorized && <div className={styles.Authorization}>
                        <UnauthorizedUserIcon className={styles.Icon}/>
                        <div className={styles.Text}>
                            {localizations.userSmartIdArchiveAuthorization}
                        </div>
                        <button className={styles.Button} onClick={login}>
                            {localizations.userSmartIdArchiveAuthorizationButton}
                        </button>
                    </div>
                }
                {
                    isAuthorized && (
                        <div className={styles.Content}>
                            <LogTableFilters
                                isEmployee={smartIdData?.bankEmployee}
                                organizationsOptions={organizations}
                                onDateRangeSelect={onChangeDateRange}
                                onChangeTypeOperation={onChangeTypeOperation}
                                onChangeTypeSignFilter={onChangeTypeSignFilter}
                                onChangeOrganizationFilter={onChangeOrganizationFilter}
                                selectedDateRange={dateRangeFilter}
                            />
                        </div>
                    )
                }
                {
                    shouldShowLoading && <Loading/>
                }
                {
                    shouldShowEmptyMessage && <Empty/>
                }
                {
                    shouldShowSmartIdLogsList && (
                        <div className={styles.Content}>
                            <div className={styles.SmartIDLogs}>
                                <MobileUserLog groupedLogs={groupedLogs}
                                               organizations={organizations}/>
                            </div>
                        </div>
                    )
                }
                {
                    showNextLogsButton && <div className={styles.NextLogs}>
                        <button className={`${styles.NextLogsButton} ${loadingNextLogsButton ? styles.Disabled : ''}`}
                                disabled={loadingNextLogsButton}
                                onClick={getNextDateRange}>
                            <div className={styles.NextLogsButtonText}>
                                <div>{localizations.smartIdHistoryNextPeriodButtonText}</div>
                                <div>{nextPackDateRange.startDate?.toLocaleDateString()} - {nextPackDateRange.endDate?.toLocaleDateString()}</div>
                            </div>
                            {
                                loadingNextLogsButton && <span className={styles.Spinner}></span>
                            }
                        </button>
                    </div>
                }
            </MobileOnlyView>
        </Fragment>
    )
}

export default UserPersonalSmartIdArchive