import React, {useEffect, useRef, useState} from 'react';
import styles from './DateRangePicker.module.css';
import {ReactComponent as CalendarIcon} from './calendarIcon.svg';
import {ReactComponent as ArrowDropUpIcon} from './arrow_drop_up_icon.svg';
import {ReactComponent as ArrowDropDownIcon} from './arrow_drop_down.svg';
import {ReactComponent as ArrowLeft} from './keyboard_arrow_left.svg';
import {ReactComponent as ArrowRight} from './keyboard_arrow_right.svg';
import {useOutsideClickDetector} from "../../../../hooks/useOutsideClickDetector";
import {ReactComponent as CancelIcon} from './cancel_icon.svg';
import BrowserView from '../../../../views/BrowserView';
import TabletView from '../../../../views/TabletView';
import MobileView from '../../../../views/MobileOnlyView';
import ReactDOM from "react-dom";
import localizations from "../../../../localizations/localizations";


export default function DateRangePicker({onDateRangeSelect, selectedDateRange}) {

    const [openCalendarTab, setOpenCalendarTab] = useState(false);
    const [isDateRangeSelected, setIsDateRangeSelected] = useState(false);
    const [useInitialTitle, setUseInitialTitle] = useState(true);
    const [dateRange, setDateRange] = useState({
        startDate: undefined,
        endDate: undefined,
    });
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const currentDate = new Date();
    const dropdownRef = useRef(null);
    const frameRef = useRef(null);
    useOutsideClickDetector(frameRef, () => closeCalendarWindow(), [dropdownRef]);

    useEffect(() => {
        if (selectedDateRange) {
            setDateRange(selectedDateRange);
        }
    }, [selectedDateRange]);

    useEffect(() => {
        const handleCloseDropdowns = () => {
            closeCalendarWindow();
        };

        window.addEventListener("closeDropdowns", handleCloseDropdowns);

        return () => {
            window.removeEventListener("closeDropdowns", handleCloseDropdowns);
        };
    }, []);

    const toggleCalendar = () => {
        setOpenCalendarTab(!openCalendarTab);
    };

    const closeCalendarWindow = () => {
        setOpenCalendarTab(false);
    }

    const handleDayClick = (day) => {
        if (day > currentDate) {
            return; // если дата больше текущей, ничего не делаем
        }
        setUseInitialTitle(false);

        if (!dateRange.startDate || (dateRange.startDate && dateRange.endDate)) {
            // If startDate is not set, or both startDate and endDate are set, set startDate to the selected day
            setDateRange({startDate: day, endDate: null});
        } else {
            // If the selected day is equal to startDate, do nothing
            if (day.toDateString() === dateRange.startDate.toDateString()) {
                return; // do nothing
            }

            // If the selected day is before startDate, swap startDate and endDate
            if (day < dateRange.startDate) {
                setDateRange({startDate: day, endDate: dateRange.startDate});
            } else {
                setDateRange({...dateRange, endDate: day});
            }

            // Trigger the callback with the selected range
            if (onDateRangeSelect) {
                onDateRangeSelect({
                    startDate: day < dateRange.startDate ? day : dateRange.startDate,
                    endDate: day < dateRange.startDate ? dateRange.startDate : day
                });
            }

            setIsDateRangeSelected(true);
            closeCalendarWindow();
        }
    };

    const renderDays = () => {
        const days = [];
        const startOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
        const endOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);

        // День недели первого числа месяца (0 - воскресенье, 1 - понедельник и т.д.)
        const startDayOfWeek = startOfMonth.getDay() === 0 ? 7 : startOfMonth.getDay(); // воскресенье как 7

        // Добавляем пустые ячейки для смещения, если месяц начинается не с понедельника
        for (let i = 1; i < startDayOfWeek; i++) {
            days.push(<div key={`empty-${i}`} className={styles.EmptyDay}></div>);
        }

        // Генерация дней текущего месяца
        for (let i = 1; i <= endOfMonth.getDate(); i++) {
            const day = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), i);
            days.push(renderDay(day));
        }

        return days;
    };

    const renderDay = (day) => {

        const {startDate, endDate} = dateRange;
        const isInRange = startDate && endDate && day >= startDate && day <= endDate;
        const isRangeStart = startDate && day.toDateString() === startDate.toDateString();
        const isRangeEnd = endDate && day.toDateString() === endDate.toDateString();
        const isToday = currentDate.toDateString() === day.toDateString();
        const showToday = isToday && !isRangeEnd && !isRangeStart;

        let dayClass = '';
        if (isInRange) {
            dayClass = styles.Range;
        }
        if (isRangeStart) {
            dayClass = styles.RangeStart;
        }
        if (isRangeEnd) {
            dayClass = styles.RangeEnd;
        }

        const containerClassRangeStart = (startDate && isRangeStart && endDate) ? styles.FullRangeStartPoint : '';
        const containerClassRangeEnd = (isInRange && isRangeEnd) ? styles.FullRangeEndPoint : '';

        // Определяем первый и последний день месяца
        const firstDayOfMonth = new Date(day.getFullYear(), day.getMonth(), 1);
        const lastDayOfMonth = new Date(day.getFullYear(), day.getMonth() + 1, 0);

        // Проверяем, является ли текущий день первым или последним днем месяца и находится ли он в диапазоне
        const isFirstDayOfMonthInRange = isInRange && day.toDateString() === firstDayOfMonth.toDateString();
        const isLastDayOfMonthInRange = isInRange && day.toDateString() === lastDayOfMonth.toDateString();

        // Получаем номер дня недели (0 = воскресенье, 6 = суббота)
        const dayOfWeek = day.getDay();

        const isFirstDayOfMonthAlone = isFirstDayOfMonthInRange && dayOfWeek === 0; // Проверяем, что последний день — это понедельник

        const isLastDayOfMonthAlone = isLastDayOfMonthInRange && dayOfWeek === 1  // Понедельник

        // Устанавливаем закругление
        let customBorderRadius = '';
        if (isFirstDayOfMonthInRange) {
            customBorderRadius = '4px 0 0 4px'; // Закругляем левый край для первого дня месяца
        }
        if (isLastDayOfMonthInRange) {
            customBorderRadius = '0 4px 4px 0'; // Закругляем правый край для последнего дня месяца
        }

        if (isFirstDayOfMonthAlone || isLastDayOfMonthAlone) {
            customBorderRadius = '4px';
        }

        return (
            <div
                key={day}
                className={`${styles.Day}
                 ${containerClassRangeStart}
                 ${containerClassRangeEnd}
                  ${showToday ? styles.Today : ''}
                   ${dayClass}`}
                style={{borderRadius: customBorderRadius}}
                onClick={() => handleDayClick(day)}>
                <span>
                    {day.getDate()}
                </span>
            </div>
        );
    };

    const handleMonthChange = (direction) => {
        const newMonth = new Date(currentMonth);
        newMonth.setMonth(currentMonth.getMonth() + direction);
        setCurrentMonth(newMonth);
    };

    const resetDateRange = () => {
        setIsDateRangeSelected(false)
        setOpenCalendarTab(true)
    }

    const calculateDropdownPosition = () => {
        const rect = frameRef.current.getBoundingClientRect();
        return {
            top: rect.bottom + window.scrollY,
            left: rect.left + window.scrollX,
            width: rect.width,
        };
    };

    const dropdownStyle = openCalendarTab && calculateDropdownPosition();

    const renderDropdown = () => (
        <div className={styles.CalendarWrapperMob}
             ref={dropdownRef}
             style={{
                 position: 'absolute',
                 top: `${dropdownStyle.top + 4}px`,
                 left: `${dropdownStyle.left}px`,
                 zIndex: 999,
             }}>
            <div className={styles.MonthControl}>
                <button className={styles.ArrowButton}
                        onClick={() => handleMonthChange(-1)}>
                    <ArrowLeft/>
                </button>
                <span className={styles.DateRangeLabelText}>
                    {
                        currentMonth.toLocaleString(localizations.calendarLocaleMonth, {
                            month: 'long',
                        }) + ` ${currentMonth.getFullYear()}`
                    }
                </span>
                <button className={styles.ArrowButton}
                        onClick={() => handleMonthChange(1)}>
                    <ArrowRight/>
                </button>
            </div>
            <div className={styles.Weekdays}>
                <div>{localizations.calenderMonday}</div>
                <div>{localizations.calendarTuesday}</div>
                <div>{localizations.calendarWednesday}</div>
                <div>{localizations.calendarThursday}</div>
                <div>{localizations.calendarFriday}</div>
                <div>{localizations.calendarSaturday}</div>
                <div>{localizations.calendarSunday}</div>
            </div>
            <div className={styles.DaysGrid}>{renderDays()}</div>
        </div>
    );

    const InitialRangeTitle = () => {
        return (
            <div className={styles.DateRangeButton}>
                <CalendarIcon/>
                <div className={styles.DateRangeButtonText}>
                    {localizations.smartIdHistoryDateRangeLastMonth}
                </div>
            </div>
        )
    }

    const CustomRangeTitle = () => {
        return (
            <div className={styles.DateRangeButton}>
                <CalendarIcon/>
                <div className={styles.DateRangeButtonText}>
                    {dateRange?.startDate.toLocaleDateString('uk-UA')} - {' '}
                    {dateRange?.endDate ? dateRange.endDate.toLocaleDateString('uk-UA') : '...'}
                </div>
            </div>
        )
    }

    return (
        <>
            <BrowserView className={styles.Browser}>
                <div ref={frameRef} className={styles.DateRangePicker}>
                    {
                        isDateRangeSelected ?
                            <button onClick={resetDateRange} className={styles.ButtonSelected}>
                                <CustomRangeTitle/>
                                <CancelIcon/>
                            </button> : <button onClick={toggleCalendar} className={styles.UnselectedButton}>
                                {
                                    useInitialTitle ? <InitialRangeTitle/> : <CustomRangeTitle/>
                                }
                                {
                                    openCalendarTab ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>
                                }
                            </button>
                    }
                    {
                        openCalendarTab && (
                            <div className={styles.CalendarWrapper}>
                                <div className={styles.MonthControl}>
                                    <button className={styles.ArrowButton}
                                            onClick={() => handleMonthChange(-1)}>
                                        <ArrowLeft/>
                                    </button>
                                    <span className={styles.DateRangeLabelText}>
                                        {
                                            currentMonth.toLocaleString(localizations.calendarLocaleMonth, {
                                                month: 'long',
                                            }) + ` ${currentMonth.getFullYear()}`
                                        }
                                    </span>
                                    <button className={styles.ArrowButton}
                                            onClick={() => handleMonthChange(1)}>
                                        <ArrowRight/>
                                    </button>
                                </div>
                                <div className={styles.Weekdays}>
                                    <div>{localizations.calenderMonday}</div>
                                    <div>{localizations.calendarTuesday}</div>
                                    <div>{localizations.calendarWednesday}</div>
                                    <div>{localizations.calendarThursday}</div>
                                    <div>{localizations.calendarFriday}</div>
                                    <div>{localizations.calendarSaturday}</div>
                                    <div>{localizations.calendarSunday}</div>
                                </div>
                                <div className={styles.DaysGrid}>{renderDays()}</div>
                            </div>
                        )}
                </div>
            </BrowserView>
            <TabletView className={styles.Tablet}>
                <div ref={frameRef} className={styles.DateRangePicker}>
                    {
                        isDateRangeSelected ?
                            <button onClick={resetDateRange} className={styles.ButtonSelected}>
                                <CustomRangeTitle/>
                                <CancelIcon/>
                            </button> : <button onClick={toggleCalendar} className={styles.UnselectedButton}>
                                {
                                    useInitialTitle ? <InitialRangeTitle/> : <CustomRangeTitle/>
                                }
                                {
                                    openCalendarTab ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>
                                }
                            </button>
                    }
                    {
                        openCalendarTab && ReactDOM.createPortal(renderDropdown(), document.body)
                    }
                </div>
            </TabletView>
            <MobileView className={styles.Mobile}>
                <div ref={frameRef} className={styles.DateRangePicker}>
                    {
                        isDateRangeSelected ?
                            <button onClick={resetDateRange} className={styles.ButtonSelected}>
                                <CustomRangeTitle/>
                                <CancelIcon/>
                            </button> : <button onClick={toggleCalendar} className={styles.UnselectedButton}>
                                {
                                    useInitialTitle ? <InitialRangeTitle/> : <CustomRangeTitle/>
                                }
                                {
                                    openCalendarTab ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>
                                }
                            </button>
                    }
                    {
                        openCalendarTab && ReactDOM.createPortal(renderDropdown(), document.body)
                    }
                </div>
            </MobileView>
        </>
    );
}