import styles from "./ExpandableCard.module.css";
import Headline from "../../../Headline/Headline";
import localizations from "../../../../localizations/localizations";
import Row from "../../../Row/Row";
import {ReactComponent as ExpandLessIcon} from './expand_less.svg';
import {ReactComponent as ExpandMoreIcon} from './expand_more.svg';
import React, {useEffect, useRef, useState} from "react";

export default function ExpandableCard({
                                           smartIdLog,
                                           getDocName,
                                           convertTimestampToTime,
                                           getLocalizedTypeOperation,
                                           getLocalizedTypeKey,
                                           getCompanyName
                                       }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [listHeight, setListHeight] = useState(0);
    const listRef = useRef(null);

    useEffect(() => {
        if (listRef.current) {
            // Calculate the total height of the list
            const items = listRef.current.children;
            const totalHeight = Array.from(items).reduce((acc, item) => acc + item.offsetHeight, 0);
            setListHeight(totalHeight);
        }
    }, [smartIdLog?.documentNames]); // Recalculate when document names change

    const toggleExpand = () => {
        setIsExpanded((prev) => !prev);
    };

    return (
        <div className={styles.Card}>
            <div className={styles.Row}>
                <div className={styles.Left}>
                    <Headline contentMargin={'0'}
                              contentFontFamily={'Inter, sans-serif'}
                              contentFontWeight={'var(--caption-bold-font-weight)'}
                              contentFontSize={'var(--caption-bold-font-size)'}
                              contentLineHeight={'var(--caption-bold-line-height)'}
                              contentLetterSpacing={'var(--caption-bold-letter-spacing)'}
                              contentColor={'var(--050-black)'}>
                        {localizations.smartIdHistoryDate}
                    </Headline>
                </div>
                <div className={styles.Right}>
                    <Row horizontal={true}
                         contentFontSize={'var(--body2-font-size)'}
                         contentLineHeight={'var(--body2-line-height)'}
                         contentColor={'var(--090-black)'}>
                        {convertTimestampToTime(smartIdLog?.date)}
                    </Row>
                </div>
            </div>
            <div className={styles.Row}>
                <div className={styles.Left}>
                    <Headline contentMargin={'0'}
                              contentFontFamily={'Inter, sans-serif'}
                              contentFontWeight={'var(--caption-bold-font-weight)'}
                              contentFontSize={'var(--caption-bold-font-size)'}
                              contentLineHeight={'var(--caption-bold-line-height)'}
                              contentLetterSpacing={'var(--caption-bold-letter-spacing)'}
                              contentColor={'var(--050-black)'}>
                        {localizations.smartIdHistoryDocumentName}
                    </Headline>
                </div>
                <div className={styles.Right}>
                    <Row horizontal={true}
                         contentFontSize={'var(--body2-font-size)'}
                         contentLineHeight={'var(--body2-line-height)'}
                         contentColor={'var(--090-black)'}>
                        {getDocName(smartIdLog?.documentName)}
                    </Row>
                </div>
            </div>
            <div className={styles.Row}>
                <div className={styles.Left}>
                    <Headline contentMargin={'0'}
                              contentFontFamily={'Inter, sans-serif'}
                              contentFontWeight={'var(--caption-bold-font-weight)'}
                              contentFontSize={'var(--caption-bold-font-size)'}
                              contentLineHeight={'var(--caption-bold-line-height)'}
                              contentLetterSpacing={'var(--caption-bold-letter-spacing)'}
                              contentColor={'var(--050-black)'}>
                        {localizations.smartIdHistoryCompany}
                    </Headline>
                </div>
                <div className={styles.Right}>
                    <Row horizontal={true}
                         contentFontSize={'var(--body2-font-size)'}
                         contentLineHeight={'var(--body2-line-height)'}
                         contentColor={'var(--090-black)'}>
                        {getCompanyName(smartIdLog)}
                    </Row>
                </div>
            </div>
            <div className={styles.Row}>
                <div className={styles.Left}>
                    <Headline contentMargin={'0'}
                              contentFontFamily={'Inter, sans-serif'}
                              contentFontWeight={'var(--caption-bold-font-weight)'}
                              contentFontSize={'var(--caption-bold-font-size)'}
                              contentLineHeight={'var(--caption-bold-line-height)'}
                              contentLetterSpacing={'var(--caption-bold-letter-spacing)'}
                              contentColor={'var(--050-black)'}>
                        {localizations.smartIdHistoryService}
                    </Headline>
                </div>
                <div className={styles.Right}>
                    <Row horizontal={true}
                         contentFontSize={'var(--body2-font-size)'}
                         contentLineHeight={'var(--body2-line-height)'}
                         contentColor={'var(--090-black)'}>
                        {smartIdLog?.serviceId}
                    </Row>
                </div>
            </div>
            <div className={styles.Row}>
                <div className={styles.Left}>
                    <Headline contentMargin={'0'}
                              contentFontFamily={'Inter, sans-serif'}
                              contentFontWeight={'var(--caption-bold-font-weight)'}
                              contentFontSize={'var(--caption-bold-font-size)'}
                              contentLineHeight={'var(--caption-bold-line-height)'}
                              contentLetterSpacing={'var(--caption-bold-letter-spacing)'}
                              contentColor={'var(--050-black)'}>
                        {localizations.smartIdHistoryTypeOperation}
                    </Headline>
                </div>
                <div className={styles.Right}>
                    <Row horizontal={true}
                         contentFontSize={'var(--body2-font-size)'}
                         contentLineHeight={'var(--body2-line-height)'}
                         contentColor={'var(--090-black)'}>
                        {getLocalizedTypeOperation(smartIdLog?.type)}
                    </Row>
                </div>
            </div>
            <div className={styles.Row}>
                <div className={styles.Left}>
                    <Headline contentMargin={'0'}
                              contentFontFamily={'Inter, sans-serif'}
                              contentFontWeight={'var(--caption-bold-font-weight)'}
                              contentFontSize={'var(--caption-bold-font-size)'}
                              contentLineHeight={'var(--caption-bold-line-height)'}
                              contentLetterSpacing={'var(--caption-bold-letter-spacing)'}
                              contentColor={'var(--050-black)'}>
                        {localizations.smartIdHistoryKeyType}
                    </Headline>
                </div>
                <div className={styles.Right}>
                    <Row horizontal={true}
                         contentFontSize={'var(--body2-font-size)'}
                         contentLineHeight={'var(--body2-line-height)'}
                         contentColor={'var(--090-black)'}>
                        {getLocalizedTypeKey(smartIdLog?.certificateType)}
                    </Row>
                </div>
            </div>
            {smartIdLog?.documentNames?.length > 0 && (
                <>
                    <div className={styles.DocumentsList}
                         ref={listRef}
                         style={{
                             height: isExpanded ? `${listHeight + 10}px` : "0",
                         }}>
                        <ul>
                            {
                                smartIdLog.documentNames.map((doc, idx) => (
                                    <li key={idx} className={styles.DocumentItem}>
                                        {doc}
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className={styles.ShowAllDocs}
                         onClick={toggleExpand}>
                        <span>{!isExpanded ? localizations.smartIdHistoryShowDocuments : localizations.smartIdHistoryHydeDocuments}</span>
                        {isExpanded ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                    </div>
                </>
            )}
        </div>

    )
}