import React from 'react';
import styles from './LogTableBody.module.css'
import LogRow from "../LogRow/LogRow";


export default function LogTableBody({groupedLogs, organizations}) {
    return (
        <tbody className={styles.TableBody}>
        {
            Object
                .entries(groupedLogs)
                .map(([date, logs]) => (
                    <React.Fragment key={date}>
                        <tr className={styles.DateRow}>
                            <td colSpan="7">{date}</td>
                        </tr>
                        {
                            logs.map((smartIdLog, index) => (
                                <LogRow key={`${smartIdLog.date}-${index}`}
                                        smartIdLog={smartIdLog}
                                        organizations={organizations}/>
                            ))
                        }
                    </React.Fragment>
                ))
        }
        </tbody>
    );
}