import React, {useEffect, useRef} from "react";
import styles from './LogTableFilters.module.css'
import DateRangePicker from "./DateRangePicker/DateRangePicker";
import CustomSelectFilter from "./CustomFilter/CustomSelectFilter";
import BrowserView from '../../../views/BrowserView';
import TabletView from '../../../views/TabletView';
import MobileView from '../../../views/MobileOnlyView';
import localizations from "../../../localizations/localizations";


export default function LogTableFilters({
                                            isEmployee,
                                            organizationsOptions,
                                            onDateRangeSelect,
                                            onChangeTypeSignFilter,
                                            onChangeTypeOperation,
                                            onChangeOrganizationFilter,
                                            selectedDateRange
                                        }) {
    const filterScrollRef = useRef(null);

    const handleScroll = () => {
        const event = new CustomEvent("closeDropdowns");
        window.dispatchEvent(event);
    };

    useEffect(() => {
        const filterScrollElement = filterScrollRef.current;
        if (filterScrollElement) {
            filterScrollElement.addEventListener("scroll", handleScroll, {passive: true});
        }
        return () => {
            if (filterScrollElement) {
                filterScrollElement.removeEventListener("scroll", handleScroll);
            }
        };
    }, []);

    let typeOperationOptions = [
        {value: '1', label: localizations.smartIdHistoryTypeOperationFilterGeneration},
        {value: '2', label: localizations.smartIdHistoryTypeOperationFilterSign},
        {value: '3', label: localizations.smartIdHistoryTypeOperationFilterEncrypt},
        {value: '4', label: localizations.smartIdHistoryTypeOperationFilterDecrypt}
    ];

    let typeKeyOptions = isEmployee ? [
        {value: '1', label: localizations.smartIdHistoryTypeKeyOptionFilterFizSign},
        {value: '2', label: localizations.smartIdHistoryTypeKeyOptionFilterYurSign},
        {value: '3', label: localizations.smartIdHistoryTypeKeyOptionFilterYurStamp},
        {value: '4', label: localizations.smartIdHistoryTypeKeyOptionFilterEmployeeSign},
        {value: '5', label: localizations.smartIdHistoryTypeKeyOptionFilterEmployeeStamp},
    ] : [
        {value: '1', label: localizations.smartIdHistoryTypeKeyOptionFilterFizSign},
        {value: '2', label: localizations.smartIdHistoryTypeKeyOptionFilterYurSign},
        {value: '3', label: localizations.smartIdHistoryTypeKeyOptionFilterYurStamp},
    ];

    let defaultOrganizationOption = {value: '0', label: localizations.smartIdHistoryOrganizationFilterName}
    let defaultTypeOperationOption = {value: '0', label: localizations.smartIdHistoryTypeOperationFilterName}
    let defaultTypeKeyOption = {value: '0', label: localizations.smartIdHistoryTypeKeyOptionFilterName}
    const organizationExist = organizationsOptions && organizationsOptions[0]?.value !== "";

    return (
        <>
            <BrowserView className={styles.Browser}>
                <div className={styles.FilterContainer}>
                    <div className={styles.FilterItemDateRange}>
                        <DateRangePicker onDateRangeSelect={onDateRangeSelect}
                                         selectedDateRange={selectedDateRange}/>
                    </div>
                    {
                        organizationExist && <div className={styles.FilterItem}>
                            <CustomSelectFilter options={organizationsOptions}
                                                onChangeFilter={onChangeOrganizationFilter}
                                                defaultOption={defaultOrganizationOption}/>
                        </div>
                    }
                    <div className={styles.FilterItem}>
                        <CustomSelectFilter options={typeOperationOptions}
                                            onChangeFilter={onChangeTypeOperation}
                                            defaultOption={defaultTypeOperationOption}/>
                    </div>
                    <div className={styles.FilterItem}>
                        <CustomSelectFilter options={typeKeyOptions}
                                            onChangeFilter={onChangeTypeSignFilter}
                                            defaultOption={defaultTypeKeyOption}/>
                    </div>
                </div>
            </BrowserView>
            <TabletView className={styles.Tablet}>
                <div className={styles.FilterScroll} ref={filterScrollRef}>
                    <div className={styles.FilterContainer}>
                        <div className={styles.FilterItemDateRange}>
                            <DateRangePicker onDateRangeSelect={onDateRangeSelect}
                                             selectedDateRange={selectedDateRange}/>
                        </div>
                        {
                            organizationExist && <div className={styles.FilterItem}>
                                <CustomSelectFilter options={organizationsOptions}
                                                    onChangeFilter={onChangeOrganizationFilter}
                                                    defaultOption={defaultOrganizationOption}/>
                            </div>
                        }
                        <div className={styles.FilterItem}>
                            <CustomSelectFilter options={typeOperationOptions}
                                                onChangeFilter={onChangeTypeOperation}
                                                defaultOption={defaultTypeOperationOption}/>
                        </div>
                        <div className={styles.FilterItem}>
                            <CustomSelectFilter options={typeKeyOptions}
                                                onChangeFilter={onChangeTypeSignFilter}
                                                defaultOption={defaultTypeKeyOption}/>
                        </div>
                    </div>
                </div>
            </TabletView>
            <MobileView className={styles.Mobile}>
                <div className={styles.FilterScroll} ref={filterScrollRef}>
                    <div className={styles.FilterContainer}>
                        <div className={styles.FilterItemDateRange}>
                            <DateRangePicker onDateRangeSelect={onDateRangeSelect}
                                             selectedDateRange={selectedDateRange}/>
                        </div>
                        {
                            organizationExist && <div className={styles.FilterItem}>
                                <CustomSelectFilter options={organizationsOptions}
                                                    onChangeFilter={onChangeOrganizationFilter}
                                                    defaultOption={defaultOrganizationOption}/>
                            </div>
                        }
                        <div className={styles.FilterItem}>
                            <CustomSelectFilter options={typeOperationOptions}
                                                onChangeFilter={onChangeTypeOperation}
                                                defaultOption={defaultTypeOperationOption}/>
                        </div>
                        <div className={styles.FilterItem}>
                            <CustomSelectFilter options={typeKeyOptions}
                                                onChangeFilter={onChangeTypeSignFilter}
                                                defaultOption={defaultTypeKeyOption}/>
                        </div>
                    </div>
                </div>
            </MobileView>
        </>

    );
}