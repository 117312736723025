import React from 'react';
import styles from './MobileUserLog.module.css';
import ExpandableCard from "./ExpandableCard/ExpandableCard";

export default function MobileUserLog({groupedLogs, organizations}) {


    const operationTypes = {
        1: "Генерація ключа",
        2: "Підписання",
        3: "Шифрування",
        4: "Дешифрування"
    };
    const certificateTypes = {
        0: "Підпис",
        1: "Печатка"
    };

    function getDocName(documentName) {
        return (documentName && documentName.trim()) ? documentName : "-";
    }

    function convertTimestampToTime(timestamp) {
        const date = new Date(timestamp);
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    }

    function getLocalizedTypeOperation(typeOperation) {
        return operationTypes[typeOperation] || "-";
    }

    function getLocalizedTypeKey(typeKey) {
        return certificateTypes[typeKey] || "-";
    }

    function getCompanyName(smartIdLog) {
        const {userType = undefined, edrpou = undefined} = smartIdLog;
        if (userType === 0) {
            return "АТ КБ «ПРИВАТБАНК»"
        }
        const org = organizations.find((object) => object.value === edrpou);
        return org ? org.label : "-";
    }

    return (
        <>
            {
                Object.entries(groupedLogs)
                    .map(([date, logs]) => (
                        <React.Fragment key={date}>
                            <div className={styles.DateRow}>
                                {date}
                            </div>
                            {
                                logs.map((smartIdLog, index) => (
                                    <ExpandableCard key={index}
                                                    smartIdLog={smartIdLog}
                                                    getDocName={getDocName}
                                                    convertTimestampToTime={convertTimestampToTime}
                                                    getLocalizedTypeOperation={getLocalizedTypeOperation}
                                                    getLocalizedTypeKey={getLocalizedTypeKey}
                                                    getCompanyName={getCompanyName}
                                    />
                                ))
                            }
                        </React.Fragment>
                    ))
            }
        </>
    )
}